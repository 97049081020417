import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import AppStoreBadge from "./AppStoreBadge.svg";
import GooglePlayBadge from "./GooglePlayBadge.png";
import Fingerprint2 from "fingerprintjs2";
import { parse } from "qs";

const useStyles = makeStyles({
  badges: {
    display: "flex",
    justifyContent: "center",

    maxWidth: "80%",
    marginTop: "10rem",
  },
  appleContainer: {
    alignSelf: "center",
  },
  apple: {
    width: "100%",
    align: "center",
  },
  google: {
    width: "25%",
  },
});

const bundleConfig: any = {
  "44066": {
    url: "https://demo1.rakutenadvertising.io",
  },
  "45984": {
    url: "https://demo2.rakutenadvertising.io",
  },
};

export const Fallback = () => {
  const { badges, appleContainer, apple, google } = useStyles();

  return (
    <Container>
      <div className={badges}>
        <div className={appleContainer}>
          <a href="https://testflight.apple.com/join/eWRRB9ha">
            <img className={apple} src={AppStoreBadge} alt="" />
          </a>
        </div>
        <a href="https://play.google.com/store/apps/details?id=com.rakutenadvertising.demo">
          <img className={google} src={GooglePlayBadge} alt="" />
        </a>
      </div>
    </Container>
  );
};

export const RADFallback = (props: any) => {
  const { badges, apple, google } = useStyles();
  const [attrData, setAttrData] = useState({});
  const location = useLocation();

  function fingerDevice() {
    setTimeout(function () {
      var options = {excludes: {fonts: true, webgl: true, plugins: true, enumerateDevices:true, canvas:true}}
      Fingerprint2.get(options, async function (components: Array<object>) {
        const finger = components.reduce((acc: any, cur: any) => {
          acc[cur.key] = cur.value;
          return acc;
        }, {});

        const ipDataPromise = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipDataPromise.text();
        const ip = JSON.parse(ipData).ip;

        finger.ip = ip;

        const parsedQuery = parse(location.search.substring(2));
        parsedQuery.bundleId = location.pathname.substring(4);

        console.log(parsedQuery.bundleId);

        finger.redirect_url = bundleConfig[parsedQuery.bundleId].url;
        const storeDeviceresult = await fetch(
          "https://attribution-sdk-endpoint-ff5ckcoswq-uc.a.run.app/device-register",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ finger, parsedQuery }),
          }
        );

        if (bundleConfig[parsedQuery.bundleId].url) {
          window.location.assign(bundleConfig[parsedQuery.bundleId].url);
        } else {
          console.log("Wrong bundle id");
          window.location.assign("https://click.rakutenadvertising.io");
        }

        setAttrData(finger);
      });
    }, 500);
  }

  useEffect(() => {
    fingerDevice();
  }, []);

  return (
    <Container className={badges}>
      <h2>Redirects to customer page...</h2>
    </Container>
  );
};
